import React, { useEffect } from "react"
import { navigate } from "gatsby"

const Login = () => {
  useEffect(() => {
    navigate("/zaloguj")
  }, [])

  return <></>
}

export default Login